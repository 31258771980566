<template>
  <div class="home">
    <transition name='left'>
      <div class="content_left">
        <div class="backbg one">
          <div class="title">人口信息</div>
          <div class="content">
            <div class="flex-between-center">
              <div class="zong">总人口：{{ detail.populationNum || 0 }}人</div>
              <div class="flex-center">
                <img src="@/assets/img/man.png" alt="">
                <span>男性｜{{ detail.maleNum || 0 }}人</span>
              </div>
              <div class="flex-center">
                <img src="@/assets/img/woman.png" alt="">
                <span>女性｜{{ detail.femaleNum || 0 }}人</span>
              </div>
            </div>
            <div class="flex-between-center">
              <div class="pie" ref="people"></div>
              <div class="colorword">
                <div class="flexb" v-for="(item, index) of detail.ageAreaList" :key="index">
                  <div class="color" :style="'background-color:' + peoplecolor[index]"></div>
                  <div class="word">{{ item.name }}</div>
                  <div class="line"></div>
                  <div>{{ item.value }}人</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="backbg two backbgNobottom">
          <div class="title">村庄荣誉</div>
          <div class="content">
            <div class="content_detail">
              <div v-for="(item, index) of detail.villageHonourList" :key="index"
                @click="getpartybiImageTextdetail(item.id, 1)">
                <div class="bg-img" :style="'background-image: url(' + item.coverUrl + ')'"></div>
                <div class="titleDot">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div class="table">
      <apopup ref="table" top="228" :title="lowincomeDetail.name + '详情'" :padding="false">
        <!-- 边缘 -->
        <div class="" v-if="lowincomeDetail.type == 1">
          <div class="tabletop grid grid1">
            <div>行政区划</div>
            <div>申请人</div>
            <div>出生日期</div>
            <div>致贫原因</div>
            <div>低保类型</div>
            <div>家庭总人口数</div>
          </div>
          <div class="overheight">
            <div class="grid grid1" v-for="(item, index) in lowincomePage" :key="index">
              <div>{{ item.villageName }}</div>
              <div>{{ item.name }}</div>
              <div>{{ item.birthday }}</div>
              <div>{{ item.poorReason }}</div>
              <div>{{ item.lowIncomeType }}</div>
              <div>{{ item.familyMemberNum }}</div>
            </div>
          </div>
        </div>
        <!-- 在册 -->
        <div class="" v-if="lowincomeDetail.type == 2">
          <div class="tabletop grid grid2">
            <div>行政区划</div>
            <div>人员姓名</div>
            <div>性别</div>
            <div>出生日期</div>
            <div>申请类别</div>
            <div>民族</div>
            <div>户口性质</div>
            <div>健康状况</div>
            <div>低保类别</div>
          </div>
          <div class="overheight">
            <div class="grid grid2" v-for="(item, index) in lowincomePage" :key="index">
              <div>{{ item.villageName }}</div>
              <div>{{ item.name }}</div>
              <div>{{ item.gender ? '男' : '女' }}</div>
              <div>{{ item.birthday }}</div>
              <div>{{ item.poorReason }}</div>
              <div>{{ item.nationality }}</div>
              <div>{{ item.registeredResidenceType ? '城镇户口' : '农村户口' }}</div>
              <div>{{ item.healthStatus }}</div>
              <div>{{ item.lowIncomeType }}</div>
            </div>
          </div>
        </div>
        <!-- 特困 -->
        <div class="" v-if="lowincomeDetail.type == 3">
          <div class="tabletop grid grid3">
            <div>行政区划</div>
            <div>姓名</div>
            <div>出生日期</div>
            <div>类别</div>
            <div>供养方式</div>
            <div>家庭总人口数</div>
            <div>供养人数</div>
            <div>人员类别</div>
          </div>
          <div class="overheight">
            <div class="grid grid3" v-for="(item, index) in lowincomePage" :key="index">
              <div>{{ item.villageName }}</div>
              <div>{{ item.name }}</div>
              <div>{{ item.birthday }}</div>
              <div>{{ item.poorReason }}</div>
              <div>{{ item.supportMode }}</div>
              <div>{{ item.familyMemberNum }}</div>
              <div>{{ item.supportPersonNum }}</div>
              <div>{{ item.lowIncomeType }}</div>
            </div>
          </div>
        </div>
      </apopup>
    </div>

    <div class="ImageText">
      <apopup ref="ImageText" top="228" :title="biImageTextType == 1 ? '村庄荣誉' : biImageTextType == 2 ? '通知公告' : '会议数据'">
        <div class="detail_title">{{ biImageTextDetail.title }}</div>
        <div class="detail_time">{{ biImageTextDetail.publishTime }}</div>
        <div class="detail_main" v-html="content"></div>
      </apopup>
    </div>

    <transition name='content_center_top'>
      <div class="content_center_top">
        <div v-for="(item, index) in centetTop" :key="index" @click="getpartylowincomepage(item)">
          <card :num="detail[item.num] || 0" :name="item.name" angin="center" />
        </div>
      </div>
    </transition>

    <transition name='center'>
      <div class="content_center">
        <div v-show="islist">
          <div class="detail_top bg-img huaxiang">
            <div>党员画像</div>
          </div>
          <div class="flex-between-center">
            <div class="swiper_cur swiper-button-prev"></div>
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in partyMemberList" :key="index">
                  <div class="box2">
                    <div v-for="(items, indexs) in item" :key="indexs" :data-index="index" :data-indexs="indexs">
                      {{ items.name }}
                    </div>
                  </div>
                  <!-- <div class="box" :data-index="index">
                    <div class="bg-img" :data-index="index" v-if="item.headUrl"
                      :style="'background-image: url(' + item.headUrl + ')'"></div>
                    <div class="bg-img" :data-index="index" v-else
                      :style="'background-image: url(' + require('@/assets/img/dangyuan.png') + ')'"></div>
                    <div class="name" :data-index="index">{{ item.name }}</div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="swiper_cur swiper-button-next"></div>
          </div>
        </div>
        <div class="dangdetail" v-show="!islist">
          <div class="detail_top bg-img" @click="goback()">
            <img src="@/assets/img/back.png" alt="">
            <div>党员详情</div>
          </div>
          <div class="main">
            <div class="main-right">
              <div class="main-right-top"><b>{{ memberdetail.biUserName }} ｜</b> {{ memberdetail.department }} </div>
              <div class="flex-between-center">
                <div>
                  <div class="hui">党龄</div>
                  <div>{{ memberdetail.partyAge }}年+</div>
                </div>
                <div>
                  <div class="hui">出生时间</div>
                  <div>{{ memberdetail.birthday }}</div>
                </div>
                <div>
                  <div class="hui">入党时间</div>
                  <div>{{ memberdetail.joinTime }}</div>
                </div>
             
              </div>
              <div class="flex-between-center">
                <div>
                  <div class="hui">性别</div>
                  <div>{{ memberdetail.gender ? '男' : '女' }}</div>
                </div>
                <div>
                  <div class="hui">年龄</div>
                  <div>{{ memberdetail.age }}</div>
                </div>
                <div>
                  <div class="hui">党员职务</div>
                  <div>{{ memberdetail.position || "正式党员" }}</div>
                </div>
                <!-- <div>
                  <div class="hui">走访数</div>
                  <div>{{ memberdetail.visitNum || 0 }}</div>
                </div>
                <div>
                  <div class="hui">会议签到率</div>
                  <div>{{ memberdetail.joinMeetingPercent || 0 }}%</div>
                </div>
                <div>
                  <div class="hui">心得数</div>
                  <div>{{ memberdetail.expNum || 0 }}</div>
                </div>
                <div>
                  <div class="hui">通知查看率</div>
                  <div>{{ memberdetail.viewNoticePercent || 0 }}%</div>
                </div> -->
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name='right'>
      <div class="content_right">
        <div class="backbg one">
          <div class="title">连心分组占比</div>
          <div class="content">
            <div class="flex-between-center">
              <div class="pie" ref="wuzi"></div>
              <div class="colorword">
                <div class="flexb" v-for="(item, index) of detail.partyTeamList" :key="index">
                  <div class="color" :style="'background-color:' + wuzicolor[index]"></div>
                  <div class="word">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="backbg two">
          <div class="title">党员数据</div>
          <div class="tab">
            <div :class="dangactive == 0 ? 'avtive' : ''" @click="dangcheck(0)">年龄分布</div>
            <div :class="dangactive == 1 ? 'avtive' : ''" @click="dangcheck(1)">党龄分布</div>
            <div :class="dangactive == 2 ? 'avtive' : ''" @click="dangcheck(2)">性别分布</div>
          </div>
          <div class="content">
            <div class="dangbar" ref="dangbar" v-show="dangactive == 0"></div>
            <div class="flex-between-center" v-show="dangactive == 1">
              <div class="pie" ref="dangage"></div>
              <div class="colorword">
                <div class="flexb" v-for="(item, index) of detail.partyAgeAreaList" :key="index">
                  <div class="color" :style="'background:' + danglingcolor[index]"></div>
                  <div class="word">{{ item.name }}</div>
                  <div class="line"></div>
                  <div>{{ item.value }}人</div>
                </div>
              </div>
            </div>
            <div class="sex" v-show="dangactive == 2">
              <div class="man">
                <div class="flex-between-center">
                  <div class="flex-center">
                    <img src="@/assets/img/man2.png" alt="">
                    <div class="fontb">男性</div>
                    <div class="fonts">{{ detail.partyMaleNum }}人</div>
                  </div>
                  <div class="flex-center">
                    <div class="fontb">{{ detail.MalePercent }}</div>
                    <div class="fonts">%</div>
                  </div>
                </div>
                <div class="hengline">
                  <div class="heng" :style="'width:' + detail.MalePercent + '%'"></div>
                </div>
              </div>
              <div class="woman">
                <div class="flex-between-center">
                  <div class="flex-center">
                    <img src="@/assets/img/woman2.png" alt="">
                    <div class="fontb">女性</div>
                    <div class="fonts">{{ detail.partyFemaleNum }}人</div>
                  </div>
                  <div class="flex-center">
                    <div class="fontb">{{ detail.FemalePercent }}</div>
                    <div class="fonts">%</div>
                  </div>
                </div>
                <div class="hengline">
                  <div class="heng" :style="'width:' + detail.FemalePercent + '%'"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="backbg backbgNobottom neighbourhood">
          <div class="title">党员内部发布数据</div>
          <div class="tabwidth" id="tabwidth" ref="tabwidth">
            <div :class="active == 0 ? 'avtive' : ''" @click="check(0,0)">通知公告</div>
            <div :class="active == 6 ? 'avtive' : ''" @click="check(6,0)">党员学习</div>
            <div :class="active == 1 ? 'avtive' : ''" @click="check(1,1)">会议数据</div>
            <div :class="active == 7 ? 'avtive' : ''" @click="check(7,2)">会议通知</div>
            <div :class="active == 8 ? 'avtive' : ''" @click="check(8,3)">学习心得</div>
            <div :class="active == 9 ? 'avtive' : ''" @click="check(9,3)">汇报成果</div>
          </div>
          <div class="content">
            <div class="flex-between-center shuliang">
              <div class="shu-left">已发布通知公告数量/条</div>
              <div class="boder_group">
                <div class="boder" v-for="(item, index) of neighbourNum" :key="index">
                  {{ item }}
                </div>
              </div>
            </div>
            <div class="list">
              <div class="flex-between-center" v-for="(item, index) of neighbourhood" :key="index"
                @click="getpartybiImageTextdetail(item.id)">
                <div class=" titleDot">{{ item.title }}</div>
                <div class="data">{{ item.publishTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from 'swiper'
import card from '@/components/card.vue'
import apopup from '@/components/apopup.vue'
import {
  imageTextpage,
  partyoverview, partymemberdetail, partylowincomepage, partylowincomedetail, partybiImageTextdetail
} from '@/api/permission'
export default {
  data() {
    return {
      villageId: "",
      peoplecolor: ["#609EFF", "#BCFAFA", "#34B4B4", "#524BFF", "#A5B3FF"],
      wuzicolor: ["#9EB1FF", "#61EEFF", "#FDDD60", "#4892FF", "#7CFFB2"],
      danglingcolor: ["#4892FF", "#7CFFB2", "#FDDD60", "#C478DD", "#57D9F9"],
      detail: {},
      active: 6,
      dangactive: 1,
      islist: true,
      myswiper: null,
      memberdetail: {},
      neighbourhood: [],
      neighbourNum: [],
      centetTop: [
        { name: "低保边缘户", num: "subsistenceEdgeNum", type: 1 },
        { name: "低保在册户", num: "subsistenceEdgeRegisteredNum", type: 2 },
        { name: "特困户", num: "poorHouseholdNum", type: 3 },
      ],
      lowincomeDetail: {},
      lowincomePage: [],
      showLowincome: false,
      biImageTextType: false,
      biImageTextDetail: {},
      content: "",
      flag: true,
      partyMemberList: [],
    };
  },
  watch: {
    EntityData(newval, oldval) {
      console.log(newval, oldval)
      // 调用函数
      this.focusMap(newval)
    }
  },
  computed: {
    EntityData() {
      return this.$store.state.EntityData
    }
  },
  components: {
    card, apopup
  },
  methods: {
    _axios() {
      partyoverview({ villageId: this.villageId }).then(res => {
        this.detail = res;
        let partyFemaleNum = res.partyFemaleNum || 0;
        let partyMaleNum = res.partyMaleNum || 0;
        let FemalePercent = partyFemaleNum == 0 && partyMaleNum == 0 ? 0 : ((partyFemaleNum / (partyFemaleNum + partyMaleNum)) * 100).toFixed(1);
        let MalePercent = partyFemaleNum == 0 && partyMaleNum == 0 ? 0 : ((partyMaleNum / (partyFemaleNum + partyMaleNum)) * 100).toFixed(1);
        this.detail.FemalePercent = FemalePercent;
        this.detail.MalePercent = MalePercent;
        this.creatchart();
        this.creatchart2();
        this.dangcheck(0);
        this.check(0);
        this.goback();
        res.partyMemberList.forEach((item, index) => {
          let ins = Math.floor(index / 12);
          if (!this.partyMemberList[ins]) {
            this.partyMemberList[ins] = [];
          }
          this.partyMemberList[ins].push(item);
        });
      })
    },
    getpartymemberdetail(id, type) {
      let data = {}
      if (type) {
        data = { biUserId: id }
      } else {
        data = { mapMarkId: id }
      }
      partymemberdetail(data).then(res => {
        this.memberdetail = res;
        this.islist = false;
        this.myswiper = null;
      })
    },
    getpartylowincomepage(item) {
      this.$refs['table'].close();
      this.$refs['ImageText'].close();
      this.lowincomeDetail = item;
      partylowincomepage({
        pageNum: 1,
        pageSize: 999,
        personType: item.type,
        villageId: this.villageId
      }).then(res => {
        this.$refs['table'].show();
        this.showLowincome = true;
        this.lowincomePage = res.records;
      })
    },
    getpartylowincomedetail(val) {
      this.$refs['table'].close();
      this.$refs['ImageText'].close();
      partylowincomedetail({
        mapMarkId: val.businessEntityId,
        villageId: val.businessVillageId
      }).then(res => {
        if (!res) return;
        this.lowincomeDetail = { name: "低保边缘户", num: "subsistenceEdgeNum", type: 1 }
        this.$refs['table'].show();
        this.lowincomePage = [res];
      })
    },
    getpartybiImageTextdetail(id, type) {
      this.$refs['table'].close();
      this.$refs['ImageText'].close();
      partybiImageTextdetail({
        id: id,
      }).then(res => {
        if (!res) return;
        this.biImageTextDetail = res;
        let richtext = res.content || "";
        const regex = new RegExp("<img", "gi");
        richtext = richtext.replace(regex, `<img style="max-width: 100%;"`);
        this.content = richtext;
        this.$refs['ImageText'].show();
        if (type) {
          this.biImageTextType = type;
        } else {
          this.biImageTextType = this.active ? 3 : 2;
        }
      })
    },
    focusMap(val) {
      if (val.type == 4) {
        this.getpartymemberdetail(val.businessEntityId)
      } else {
        this.getpartylowincomedetail(val)
      }
    },
    goback() {
      this.islist = true;
      this.$nextTick(() => {
        this.getSwiper();
      })
    },
    getTextpage(index){
      imageTextpage({
        columnLocationList:[index],
        pageNum:1,
        pageSize:10,
      }).then(res=>{
        this.neighbourhood=res.records;
        this.neighbourhood.forEach(e=>{
            e.publishTime=e.createTime.substring(0,10)
            e.id=e.imageTextId
        })
      })
    },  
    check(value,index) {
      this.active = value;
      var scrollbar =  this.$refs.tabwidth
      scrollbar.scrollLeft = index*100-50;
      if (value==1) {
        this.neighbourhood = this.detail.meetingTextList;
        this.neighbourNum = this.detail.meetingTextNum ? this.detail.meetingTextNum.toString().split("") : [];
      } else if(value==6){  //党内学习
        this.neighbourNum = this.detail.dangNeiXueXiNum.toString().split("");
        this.getTextpage(6)
      }else if(value==0) {
        this.neighbourhood = this.detail.noticeTextList;
        this.neighbourNum = this.detail.noticeTextNum ? this.detail.noticeTextNum.toString().split("") : [];
      }else if(value==7){ //会议通知
        this.neighbourNum = this.detail.huiYiTongZhi7Num.toString().split("");
        this.getTextpage(7)
      }else if(value==8){ //学习心得
        this.neighbourNum = this.detail.xueXiXinDe8Num.toString().split("");
        this.getTextpage(8)
      }else if(value==9){ //汇报成果
        this.neighbourNum = this.detail.huiBaoChengGuo9Num.toString().split("");
        this.getTextpage(9)
      }
    },
    dangcheck(value) {
      this.dangactive = value;
      this.$nextTick(() => {
        if (value == 0) {
          this.creatchartage();
        } else if (value == 1) {
          this.creatchartdangage();
        }
      })
    },
    //左上 人口年龄
    creatchart() {
      let option;
      let mychart = this.$echarts.init(this.$refs.people);
      option = {
        series: [
          {
            name: '人口分布',
            type: 'pie',
            selectedMode: 'single',
            avoidLabelOverlap: false,
            radius: [0, '50%'],
            label: {
              position: 'inner',
              fontSize: 12,
              color: "#fff"
            },
            labelLine: {
              show: false
            },
            data: [
              { value: this.detail.maleNum, name: '男' },
              { value: this.detail.femaleNum, name: '女' },
            ],
            color: ["#5F83FF", "#C893C3"],
            right: '10%',
          },
          {
            name: '人口分布',
            type: 'pie',
            radius: ['72%', '90%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '10',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.detail.ageAreaList,
            color: this.peoplecolor,
            right: '10%',
          }
        ],
      };
      mychart.setOption(option);
    },
    // 右上 连心分布
    creatchart2() {
      let option;
      let mychart = this.$echarts.init(this.$refs.wuzi);
      option = {
        label: {
          alignTo: 'edge',
          formatter: '{name|{d}%}\n{time|{c}户}',
          minMargin: 5,
          edgeDistance: 10,
          lineHeight: 15,
          rich: {
            name: {
              fontSize: 14,
              color: '#fff'
            },
            time: {
              fontSize: 10,
              color: '#fff'
            }
          }
        },
        series: [
          {
            name: '连心分组占比',
            type: 'pie',
            radius: ["15%", "80%"],
            roseType: 'area',
            itemStyle: {
              borderRadius: 5
            },
            data: this.detail.partyTeamList,
            color: this.wuzicolor,
          }
        ]
      };
      mychart.setOption(option);
    },
    // 右2 党员年龄
    creatchartage() {
      let option;
      let dataAxis = []
      let data = []
      this.detail.partyMemberAgeAreaList.map((item) => {
        dataAxis.push(item.name);
        data.push(item.value);
      })
      let that = this;
      let mychart = this.$echarts.init(this.$refs.dangbar);
      option = {
        xAxis: {
          data: dataAxis,
          axisLabel: {
            color: '#fff'
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          axisLabel: {
            color: "#fff"
          },
          axisTick: {
            show: true
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            type: 'bar',
            showBackground: false,
            itemStyle: {
              color: that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 1, color: 'rgba(105, 193, 255, 0)' },
                { offset: 0, color: '#69C1FF' }
              ])
            },
            emphasis: {
              itemStyle: {
                color: that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#69C1FF' },
                  { offset: 1, color: 'rgba(105, 193, 255, 0)' }
                ])
              }
            },
            data: data
          }
        ]
      };
      mychart.setOption(option);
    },
    //右2 党龄
    creatchartdangage() {
      let option;
      let mychart = this.$echarts.init(this.$refs.dangage);
      option = {
        series: [
          {
            name: '人口分布',
            type: 'pie',
            radius: ['40%', '80%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            itemStyle: {
              borderRadius: 2,
              borderColor: '#fff',
              borderWidth: 1
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '10',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.detail.partyAgeAreaList,
            color: this.danglingcolor,
            right: "10%"
          }
        ],
      };
      mychart.setOption(option);
    },
    getSwiper() {
      let self = this;
      this.myswiper = new Swiper('.swiper-container', {
        direction: 'horizontal', // 垂直切换选项
        // mousewheel: true, //滚轮
        loop: true, // 设置为true 则开启loop模式
        slidesPerView: "auto", // 设置slider容器能够同时显示的slides数量(carousel模式)。类型：number or auto
        centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
        // spaceBetween: 15, // 在slide之间设置距离（单位px）。
        loopAdditionaSlider: 0, // loop模式下会在slides前后复制若干个slide,，前后复制的个数不会大于原总个数。
        initialSlide: 0,
        // autoplay: {
        //   //自动开始
        //   delay: 5000, //时间间隔
        //   disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        // },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          tap(ev) {
            //获取不到this，提前定义self = this
            let index = ev.target.getAttribute('data-index');
            let indexs = ev.target.getAttribute('data-indexs');
            let id = self.partyMemberList[index][indexs].biUserId;
            if (self.flag) {
              setTimeout(() => {
                self.getpartymemberdetail(id, 1);
                self.flag = true;
              }, 300);
            }
            self.flag = false;
          },
        },
      })
    },
  },
  mounted() {
    this.villageId = this.$store.state.villageId;
    this.$store.commit("SET_showtype", "4,1637670588280");
    this._axios();
  },
};
</script>

<!--添加个空的style 占位-->
<style src="@/assets/css/contentfixed.css" scoped>

</style>
<style lang="scss" src="@/assets/css/neighbourhood.scss" scoped>

</style>
<style lang="scss" src="@/assets/css/home.scss" scoped>

</style>
<style lang="scss" scoped>

</style>